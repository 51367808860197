$(function(){


	// Окно документа
	var $window = $(window);




	/**
	 * Раскрывающиеся меню в мобильных версиях
	 */


	// Кнопки раскрытия
	var $menu_expander = $('.menu-expander');


	// Раскрытие/сокрытие меню при клике по кнопке
	$menu_expander
		.click(function(e){
			var $button = $(this);
			var $nav = $button.data('target') ? $($button.data('target')) : $button.next();

			if ($button.hasClass('active'))
			{
				$nav.slideUp(200);
			}
			else
			{
				$nav.slideDown(200);
			}

			$button.toggleClass('active');
		});


	// Горизонтальная прокрутка таблицы
	var tableScroll = $('.typography').find('table'),
		tblWidth = $('tr').width(),
		duration = 5000;

	$(document).on('keydown',function(e){
		if(e.which == 39) {
			tableScroll.animate({
				scrollLeft: tblWidth
			}, duration, 'linear');
		} else if(e.which == 37) {
			tableScroll.animate({
				scrollLeft: 0
			}, duration, 'linear');
		}
	});
	$(document).keyup(function(){
		tableScroll.stop(true);
	});


	// Плавная прокрутка
	$('a[href^="#"]').on('click', function(e){
		e.preventDefault();

		var anchor = $(this);
		var name = anchor.attr('href').substr(1);

		$('body')
			.stop()
			.animate({
				scrollTop: $('a[name=' + name + ']').offset().top - $('.nav').height()
			}, 500);
	});

});