$(function(){


	var $document = $(document);
	var $body = $("body");

	var $viewer;			// Просмотровик
	var $view;				// Контейнер с большим изображением
	var $thumbs;		// Контейнер миниатюр

	var images;				// Имена файлов изображений
	var clicked_filename;	// Имя файла элемента-инициатора
	var path;				// Путь к показываемым изображениям
	var path_thumbs;		// Путь к миниатюрам




	$document.on("click", "img[data-view]", function(e) {

		/* Инициализация */
		images = [];

		var $clicked_thumb = $(this);
		clicked_filename = $clicked_thumb.data("view");
		path = $clicked_thumb.data("path");
		path_thumbs = $clicked_thumb.attr("src").replace(clicked_filename, "");




		/* Слушатель ресайзинга */
		$(window).resize(on_resize);




		/* Наполнение массива изображений */
		var set = $clicked_thumb.data("set");

		if (set)
		{
			// Сериализованный список
			if (isNaN(set))
			{
				images = set.split(",");
			}
			// Все элементы галереи имеют миниатюры,
			// объединенные названием набора
			else
			{
				$("img").filter("[data-set='" + set + "']").each(function(index, element) {
					images.push($(element).data("view"));
				});
			}
		}





		/* Убирается полоса прокрутки у тела сайта */
		$body.addClass("hidden-scroll");




		/* Создание просмотровика и добавление в документ с анимацией */
		$viewer = $("<div class='viewer' style='opacity: 0'>");
		$body.append($viewer);
		$viewer.animate(
			{ opacity : 1 },
			200);




		/* Миниатюры изображений */
		if (images.length > 1)
		{
			$thumbs = $("<div class='thumbs'>");

			for (var i = 0; i < images.length; i++)
			{
				var $thumb = $("<img src='" + path_thumbs + images[i] + "' class='rounded shadow'>").appendTo($thumbs);
			}

			$thumbs
				.appendTo($viewer)

				// Показ большого изображения
				.on("click", "img", function(e) {
					var $image = $(this);
					var filename = $image.attr("src").replace(path_thumbs, "");

					if (filename != images[get_current_image_index()])
					{
						show_image(filename);
					}
				});
		}




		/* Контейнер большого изображения */
		$view = $("<div class='view'>");
		update_geometry();
		show_image(clicked_filename);
		$view.appendTo($viewer);




		/* Кнопка закрытия просмотровика */
		var $close = $("<div class='exit icon-cancel'></div>")
			.disableSelection()
			.click(close_viewer);
		$viewer.append($close);




		/* Кнопки "Вперед" и "Назад" */
		if (images.length > 1)
		{
			var $next = $("<div class='icon-right'></div>")
				.disableSelection()
				.click(function(e) { show_next_image(); });
			$viewer.append($next);

			var $previous = $("<div class='icon-left'></div>")
				.disableSelection()
				.click(function(e) { show_previous_image(); });
			$viewer.append($previous);
		}
	});




	/* Закрытие просмотровика */
	function close_viewer(e)
	{
		if ($viewer)
		{
			$viewer.animate(
				{ opacity: 0 },
				200,
				function(e) {
					$(this).remove();
				});
		}

		$body.removeClass("hidden-scroll");
	}





	/* Получение индекса изображения */
	function get_current_image_index()
	{
		if ($view && $view.length)
		{
			var filename = $view.find("img").attr("src").replace(path, "");
			return images.indexOf(filename);
		}
		else
		{
			return images.indexOf(clicked_filename);
		}
	}




	/* Расчет геометрии контейнера большого изображения */
	function update_geometry()
	{
		if ($view)
		{
			// Установка высоты контейнера большого изображения с учетом миниатюр
			if ($thumbs && $thumbs.length)
			{
				$view.height($viewer.height() - $thumbs.outerHeight());
			}
			else
			{
				$view.height($viewer.height());
			}


			// Обновление отступа изображения для вертикальной центровки
			var $image = $view.children();

			if ($image.length)
			{
				if ($view.height() >= $image.height())
				{
					$image.css("margin-top", ($view.height() - $image.height()) / 2 + "px");
				}
			}


			// Вертикальные позиии кнопок "Вперед" и "Назад"
			if (images.length > 1) {
				var $next = $viewer.children(".next");
				var $previous = $viewer.children(".previous");

				$next.css("top", $view.height() / 2 - $next.height() - 20 + "px");
				$previous.css("top", $view.height() / 2 + 20 + "px");
			}
		}
	}




	/* Обработка ресайзинга окна */
	function on_resize(e)
	{
		update_geometry();
	}




	/* Показ изображения */
	function show_image(filename)
	{
		var $image = $("<img src='" + path + filename + "' style='opacity: 0;" + (images.length > 1 ? " cursor: pointer;" : null) + "'>")
			.click(function(e){
				show_next_image();
			});

		// По загрузке
		$image.load(function(e){

			$view.html($image);
			update_geometry();
			$image.animate(
				{ opacity: 1 },
				200
			);

			// Выбор миниаютры
			if ($thumbs && $thumbs.children().length)
			{
				$thumbs
					.children()
					.removeClass("selected")
					.eq(get_current_image_index())
					.addClass("selected");

				var $selected_thumb = $thumbs.children(".selected");


				// Расчет позиции для прокрутки
				var scroll_to_left = $selected_thumb.offset().left;
				scroll_to_left += $thumbs.scrollLeft();
				scroll_to_left += ($thumbs.outerWidth() - $selected_thumb.width()) / -2;
				scroll_to_left = scroll_to_left < 0 ? 0 : scroll_to_left;
				scroll_to_left += "px";


				// Прокрутка к миниаютере (располагается по центру экрана)
				$thumbs.scrollTo(
					scroll_to_left,
					200,
					{ axis: "x" });
			}
		});
	}




	/* Показать следующее изображение */
	function show_next_image()
	{
		var current = get_current_image_index();
		var next = current + 1;
		next = next >= images.length ? 0 : next;
		show_image(images[next]);
	}




	/* Показать предыдущее изображение */
	function show_previous_image()
	{
		var current = get_current_image_index();
		var previous = current - 1;
		previous = previous < 0 ? images.length - 1 : previous;
		show_image(images[previous]);
	}


});