$(function() {
	var icons = {
		header: "icon-down",
		activeHeader: "icon-up"
	};
	$( "#accordion" ).accordion({
		collapsible: true,
		heightStyle: "content",
		icons: icons,
	});
});