$(function () {


	// Открытие окна
	$('[data-target]').click(function (e) {
		var elem = $($(this).data('target')),
			duration = $(this).data('duration');

		elem
			.fadeToggle(duration)
			.css({'overflow-y': 'auto'});

		$('body > *:not([data-type="modal"])').foggy({
			blurRadius: 5,
			cssFilterSupport: true
		});
		$('body').css({'overflow-y': 'hidden'});
	});

	// Кнопка закрытия окна
	$('[data-behavior="close"]').on('click', function () {
		$(this).closest('[data-type="modal"]').fadeOut(200);
		$('body').css({'overflow-y': 'auto'});
		$('body > *').foggy(false);
	});

	// Фиксация цели открытия модального окна вызова замерщика
	$('[data-target="#callback"]').click(function (e) {
		yaCounter38330545.reachGoal('open-order');
	});

	// Фиксация цели вызова замерщика
	$('#recall').submit(function (e) {
		yaCounter38330545.reachGoal('make-order');
	})

});