$(function () {

	// Уведомления об отправке данных из форм
	function handlerForm($value) {
		var  handler = $($value);

		handler.submit(function(e) {
			e.preventDefault();

			var $result = $('.result');


			var method=$(this).attr('method'),
				action=$(this).attr('action'),
				data=$(this).serialize();

			$.ajax({
				type: method,
				url: action,
				data: data,
				success: function(result) {
					$result
						.html(result);
					$('#notice').fadeIn(200);
					$('body > *:not([data-type="modal"])').foggy({
						blurRadius: 5,
						cssFilterSupport: true,
					});
					handler.closest('[data-type="modal"]').fadeOut(200);

				},
				error: function (result) {
					console.log(result);
				}
			});
		});
	}

	handlerForm('#recall');
});