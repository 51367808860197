$(function () {
	$('.slick-slider').slick({
		dots: true,
		infinite: true,
		autoplay: true,
		autoplaySpeed: 3000,
		pauseOnFocus: false,
		focusOnSelect: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		speed: 500,
	});
});
