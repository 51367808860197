// $(function() {
//     $( "#slider-range" ).slider({
//       range: true,
//       min: 0,
//       max: 500,
//       values: [ 75, 300 ],
//       slide: function( event, ui ) {
//         $( "#amount" ).val( "от " + ui.values[ 0 ] + " до " + ui.values[ 1 ] );
//       }
//     });
//     $( "#amount" ).val( "от " + $( "#slider-range" ).slider( "values", 0 ) +
//       " до " + $( "#slider-range" ).slider( "values", 1 ) );
//   });

$(function() {
    // $( ".slider-range" ).slider({
    //     range: true,
    //     min: 0,
    //     max: 500,
    //     values: [ 75, 300 ],
    //     slide: function( event, ui ) {
    //         $( ".amount" ).val("От " + ui.values[ 0 ] + " до " + ui.values[ 1 ]);
    //     }
    // });
    // $( ".amount" ).val("От " + $( ".slider-range" ).slider( "values", 0 ) + " до " +
    //     $( ".slider-range" ).slider( "values", 1 ));

    /*
     * Фильтр продуктов
     */

    // Разворачивание групп параметров фильтра
    $('.toggle')

    // Обработка клика
        .click(function() {
            $(this)
                .toggleClass('active')
                .next()
                .slideToggle(100);

            init_slider_range($(this).next('.slider-range'));
        })

        // Вызов клика при загрузке страницы для использованных блоков
        .each(function(index, elem){
            // Переключатель и его контейнер
            var $toggle = $(elem);
            var $wrapper = $toggle.parent();

            // Если есть выбранные чекбоксы, то список надо развернуть
            if ($wrapper.find(':checkbox:checked').length)
            {
                $toggle.click();
                return;
            }

            // Если нет настроеного слайдера, то список его надо развернуть
            var $slider_range = $wrapper.find('.slider-range');

            if ($slider_range.length)
            {
                var $fields_values = $slider_range.find('input[type="hidden"]');
                var limit_min = $slider_range.data('min'),
                    limit_max = $slider_range.data('max'),
                    value_min = parseInt($fields_values.first().val()),
                    value_max = parseInt($fields_values.last().val());

                if (limit_min != value_min || limit_max != value_max)
                {
                    $toggle.click();
                }
            }
        })

        // Отмена выделения у контейнера
        .parent()
        .disableSelection();




    /*
     * Инициализация ползунка
     */
    function init_slider_range($wrapper) {
        // Предотвращение повторной инициализации
        if ($wrapper.children('div').length)
        {
            return false;
        }

        // Добавление необходимых элементов
        $wrapper
            .append('<input readonly>')
            .append('<div></div>');

        // Лимиты
        var limit_min = $wrapper.data('min');
        var limit_max = $wrapper.data('max');

        // Значения
        var value_min = $wrapper.find('[name*="min"]').val();
        var value_max = $wrapper.find('[name*="max"]').val();

        // Инициализация слайдера
        $wrapper
            .children('div')
            .slider({
                range: true,
                min: limit_min,
                max: limit_max,
                step: 100,
                values: [value_min, value_max],
                create: function() {
                    updateSliderRangeValues($wrapper, value_min, value_max);
                },
                slide: function(event, ui) {
                    updateSliderRangeValues($wrapper, ui.values[0], ui.values[1]);
                }
            });
    }




    /*
     * Обновление содержимого метки
     */
    function updateSliderRangeValues($wrapper, min, max)
    {
        var $label = $wrapper.find('input[readonly]');
        $label.val('От ' + min + ' до ' + max);

        $wrapper.find('[name*="min"]').val(min);
        $wrapper.find('[name*="max"]').val(max);
    }




    /*
     * Инициализация ползунка с ценой
     */
    init_slider_range($('#slider-range-price'));

});

