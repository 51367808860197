$(function(){

	// Сворачиваем разворачивам блоки в новостной ленте
	$('.news-item').find('.button').on('click', function(e) {

		var $button = $(this);
		var $div = $button.parent().find('> div');

		if ($button.hasClass('active'))
		{
			$button.text('Развернуть');
			$div.css({'overflow' : 'hidden', 'max-height' : '20rem'});
		}
		else
		{
			$button.text('Свернуть');
			$div.css({'overflow' : 'auto', 'max-height' : 'none'});
		}

		$button.toggleClass('active');
	});
});