$(function(){

	/**
	 * Слайдер
	 */
	// Окно документа
	var $window = $(window),
		$slider = $('.slider-view');

	$slider.each(function( index ){
		if ($(this).length)
		{
			var $slider_slides_wrapper = $(this).children('.slides');
			var $slider_bullets_wrapper = $(this).children('.slider-bullets');
			// var thisSlider = $slider.each();



			// Инициализация слайдера
			create_slider_bullets();
			$(this).disableSelection();

			$slider_bullets_wrapper
				.on('click', 'li', function(e){
					change_slide($(this).data('num'));
					e.stopPropagation();
				});


			$window
				.resize(function(e){
					change_slide(get_selected_slide_num(), 0);
				});
		}


		/* Создание маркеров */
		function create_slider_bullets()
		{
			$slider_bullets_wrapper.empty();

			for (var i = 0; i < $slider_slides_wrapper.children().length; i++)
			{
				var $bullet = $('<li data-num="' + i + '"></li>');

				if (i == 0)
				{
					$bullet.addClass('selected');
				}

				$slider_bullets_wrapper.append($bullet);
			}
		}


		/* Вычисление номера выбранной фотографии */
		function get_selected_slide_num()
		{
			return Number($slider_bullets_wrapper.children('.selected').data('num'));
		}


		/* Смена изображения */
		function change_slide(num, duration)
		{
			duration = isNaN(duration) ? 200 : duration;

			// Отмена выделения старого маркера и выбор нового
			$slider_bullets_wrapper
				.children()
				.removeClass('selected')
				.eq(num)
				.addClass('selected');

			$slider_slides_wrapper.animate({
				left: -num * $slider_slides_wrapper.width()
			}, duration)
		}

		var $slider_left_wrapper = $slider.children('.left');
		var $slider_right_wrapper = $slider.children('.right');

		$slider_left_wrapper
			.click(function(){
				var num = get_selected_slide_num() - 1;

				if (num < 0)
				{
					change_slide($slider_slides_wrapper.children().length - 1);
				}
				else
				{
					change_slide(num);
				}
			});

		$slider_right_wrapper
			.click(function(){
				var num = get_selected_slide_num() + 1;

				if (num == $slider_slides_wrapper.children().length)
				{
					change_slide(0);
				}
				else
				{
					change_slide(num);
				}
			});

	});

});